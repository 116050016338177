import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { omitPattern, regextPattern } from '../libs/constants';
import { useTranslation } from 'react-i18next';
import { /*calculateAge*/ scroll2Top } from '../libs/utils';
import toast from 'react-hot-toast';
import LoaderFullSwcreen from './LoaderFullSwcreen';

function RegistrationForm(props) {
  const [lastToastId, setLastToastId] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const {
    register,
    resetField,
    reset,
    handleSubmit,
    watch,
    //setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const { t } = useTranslation();

  const location = useLocation();
  const showCompanyInfo = watch('companyInfo');

  //--- ---

  const handleChange = async (e, omitPattern = '') => {
    const { value, name } = e.target;

    //---
    /*if (name === 'birthDay') {
      const currentAge = calculateAge(value, 13);
      if (currentAge <= 13) {
        showToast(t('errors.minAge', { min: 13 }), 'error', 5000);
      }
    }*/

    // apply omit pattern
    const omitRegex = omitPattern;
    const newValue = value.replace(omitRegex, '');
    e.target.value = newValue;

    // Trigger validation
    await trigger(name);
  }; //handleChange;

  const onSubmit = (data = '') => {
    const domain = new URLSearchParams(location.search)
      .get('domain')
      .toLocaleLowerCase()
      .replace('https://', '')
      .replace('http://', '');
    const courseCode = new URLSearchParams(location.search).get('courseCode');
    const role = 'student';

    //setValue('courseCode', courseCode);

    //--- evaluete if company info is present ---
    if (showCompanyInfo === false) {
      data.companyName = '';
      data.piva = '';
      data.fiscalCodeCompany = '';
      data.addressCompany = '';
      data.cityCompany = '';
      data.pecCompany = '';

      resetField('companyName');
      resetField('piva');
      resetField('fiscalCodeCompany');
      resetField('addressCompany');
      resetField('cityCompany');
      resetField('pecCompany');
    } //showCompanyInfo

    setShowLoader(true);

    data.birthDay = data.birthDay.split('/').reverse().join('-');

    const obj2Send = { ...data, domain, role, courseCode };

    try {
      fetch(`${process.env.REACT_APP_API_V2_URL}/account/register`, {
        method: 'POST',
        body: JSON.stringify(obj2Send),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status !== 200) {
            setShowLoader(false);
            showToast(data.message, 'error', 5000);
            scroll2Top();
            return true;
          }

          sessionStorage.setItem('token', data?.payload?.token || '');
          setShowLoader(false);
          props.setSuccessSubmit(true);
          reset();
        });
    } catch (error) {
      console.log(error);
    }
  }; //onSubmit;

  const showToast = (message, mode, timer = 5000) => {
    if (lastToastId) {
      toast.dismiss(lastToastId);
    }
    let id = 0;
    if (mode === 'error') {
      id = toast.error(message, {
        duration: timer,
        style: {
          borderRadius: '10px',
          background: '#f44336',
          color: '#fff',
          padding: '12px 24px',
        },
      });
    }
    setLastToastId(id);
  }; //showToast;

  useEffect(() => {
    //eslint-disable-next-line
  }, [showCompanyInfo]);

  //--- ---

  return (
    <>
      {showLoader && <LoaderFullSwcreen />}

      <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-6 mb-6 select-none">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="name"
            >
              Nome:
            </label>
            <input
              id="name"
              name="name"
              autoComplete="off"
              {...register('name', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.name, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.name),
                minLength: { value: 2, message: t('errors.minLength', { min: 2 }) },
                maxLength: { value: 30, message: t('errors.maxLength', { max: 30 }) },
              })}
              placeholder="Nome"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.name && <span className="text-red-500 text-xs italic">{errors.name.message}</span>}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="surname"
            >
              Cognome:
            </label>
            <input
              id="surname"
              name="surname"
              autoComplete="off"
              {...register('surname', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.surname, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.surname),
                minLength: { value: 2, message: t('errors.minLength', { min: 2 }) },
                maxLength: { value: 30, message: t('errors.maxLength', { max: 30 }) },
              })}
              placeholder="Cognome"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.surname && <span className="text-red-500 text-xs italic">{errors.surname.message}</span>}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="birthDay"
            >
              Data di nascita:
            </label>
            <input
              id="birthDay"
              name="birthDay"
              autoComplete="off"
              type="text"
              {...register('birthDay', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.dateIt, message: 'la data deve essere inserita nel formato DD/MM/AAAA' },
                onChange: (e) => handleChange(e, omitPattern.birthDay),
              })}
              placeholder="Data di nascita"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.birthDay && <span className="text-red-500 text-xs italic">{errors.birthDay.message}</span>}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="birthCity"
            >
              Comune di nascita:
            </label>
            <input
              id="birthCity"
              name="birthCity"
              autoComplete="off"
              {...register('birthCity', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.city, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.city),
                minLength: { value: 2, message: t('errors.minLength', { min: 2 }) },
                maxLength: { value: 30, message: t('errors.maxLength', { max: 30 }) },
              })}
              placeholder="Comune di nascita"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.birthCity && <span className="text-red-500 text-xs italic">{errors.birthCity.message}</span>}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="birthProvince"
            >
              Provincia di nascita (sigla):
            </label>
            <input
              id="birthProvince"
              name="birthProvince"
              autoComplete="off"
              {...register('birthProvince', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.province, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.province),
                minLength: { value: 2, message: t('errors.minLength', { min: 2 }) },
                maxLength: { value: 3, message: t('errors.maxLength', { max: 3 }) },
              })}
              placeholder="Provincia di nascita"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.birthProvince && (
              <span className="text-red-500 text-xs italic">{errors.birthProvince.message}</span>
            )}
          </div>

          <div className="w-full sm:w-1/2 px-1 mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="fiscalCode"
            >
              Codice Fiscale:
            </label>
            <input
              id="fiscalCode"
              name="fiscalCode"
              autoComplete="off"
              {...register('fiscalCode', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.codiceFiscale, message: t('errors.omitWarning') },
                onChange: (e) => {
                  e.target.value = e.target.value.toUpperCase();
                  handleChange(e, omitPattern.codiceFiscale);
                },
                minLength: { value: 16, message: t('errors.minLength', { min: 16 }) },
                maxLength: { value: 16, message: t('errors.maxLength', { max: 16 }) },
              })}
              placeholder="Codice Fiscale"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.fiscalCode && showCompanyInfo === true && (
              <span className="text-red-500 text-xs italic">{errors.fiscalCode.message}</span>
            )}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="phone"
            >
              Telefono:
            </label>
            <input
              id="phone"
              name="phone"
              autoComplete="off"
              {...register('phone', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.phone, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.phone),
                minLength: { value: 5, message: t('errors.minLength', { min: 5 }) },
                maxLength: { value: 14, message: t('errors.maxLength', { max: 14 }) },
              })}
              placeholder="Telefono"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.phone && <span className="text-red-500 text-xs italic">{errors.phone.message}</span>}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0"></div>
        </div>

        <div className="w-full mt-5 pl-2 pb-2 text-md border-b-2">
          <b>Indirizzo di residenza:</b>
        </div>

        <div className="flex flex-wrap mt-3">
          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="residenceCity"
            >
              Città:
            </label>
            <input
              id="residenceCity"
              name="residenceCity"
              autoComplete="off"
              {...register('residenceCity', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.city, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.city),
                minLength: { value: 2, message: t('errors.minLength', { min: 2 }) },
                maxLength: { value: 30, message: t('errors.maxLength', { max: 30 }) },
              })}
              placeholder="Città"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.residenceCity && (
              <span className="text-red-500 text-xs italic">{errors.residenceCity.message}</span>
            )}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="residenceProvince"
            >
              Provincia (sigla):
            </label>
            <input
              id="residenceProvince"
              name="residenceProvince"
              autoComplete="off"
              {...register('residenceProvince', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.province, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.province),
                minLength: { value: 2, message: t('errors.minLength', { min: 2 }) },
                maxLength: { value: 3, message: t('errors.maxLength', { max: 3 }) },
              })}
              placeholder="Provincia"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.residenceProvince && (
              <span className="text-red-500 text-xs italic">{errors.residenceProvince.message}</span>
            )}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="residenceAddress"
            >
              Indirizzo:
            </label>
            <input
              id="residenceAddress"
              name="residenceAddress"
              autoComplete="off"
              {...register('residenceAddress', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.address, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.address),
                minLength: { value: 5, message: t('errors.minLength', { min: 5 }) },
                maxLength: { value: 80, message: t('errors.maxLength', { max: 80 }) },
              })}
              placeholder="Indirizzo"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.residenceAddress && (
              <span className="text-red-500 text-xs italic">{errors.residenceAddress.message}</span>
            )}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="residenceHouseNumber"
            >
              Numero Civico:
            </label>
            <input
              id="residenceHouseNumber"
              name="residenceHouseNumber"
              autoComplete="off"
              {...register('residenceHouseNumber', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.houseNumber, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.houseNumber),
                maxLength: { value: 6, message: t('errors.maxLength', { max: 6 }) },
              })}
              placeholder="numero civico"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.residenceHouseNumber && (
              <span className="text-red-500 text-xs italic">{errors.residenceHouseNumber.message}</span>
            )}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="residenceZipCode"
            >
              CAP:
            </label>
            <input
              id="residenceZipCode"
              name="residenceZipCode"
              autoComplete="off"
              {...register('residenceZipCode', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.zipCode, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.zipCode),
                minLength: { value: 5, message: t('errors.minLength', { min: 5 }) },
                maxLength: { value: 5, message: t('errors.maxLength', { max: 5 }) },
              })}
              placeholder="CAP"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.residenceZipCode && (
              <span className="text-red-500 text-xs italic">{errors.residenceZipCode.message}</span>
            )}
          </div>
        </div>

        <div className="flex flex-wrap mt-6">
          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email:
            </label>
            <input
              id="email"
              name="email"
              autoComplete="off"
              {...register('email', {
                required: t('errors.requiredField'),
                pattern: { value: regextPattern.email, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.email),
              })}
              placeholder="Email"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.email && <span className="text-red-500 text-xs italic">{errors.email.message}</span>}
          </div>

          <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="password"
            >
              Password:
            </label>
            <input
              id="password"
              name="password"
              autoComplete="off"
              {...register('password', {
                required: t('errors.requiredField'),
                //pattern: { value: regextPattern.password, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.password),
                minLength: { value: 8, message: t('errors.minLength', { min: 8 }) },
                maxLength: { value: 14, message: t('errors.maxLength', { max: 14 }) },
              })}
              placeholder="Password"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.password && (
              <>
                <span className="text-red-500 text-xs italic">{errors.password.message}</span>

                <legend className="text-gray-800 text-xs mt-2">
                  La password deve contenere:
                  <ul className="list-disc list-inside">
                    <li>Almeno 8 caratteri e al massimo 14</li>
                    <li>Almeno una lettera maiuscola</li>
                    <li>Almeno una lettera minuscola</li>
                    <li>Almeno un numero</li>
                    <li>Almeno un carattere speciale (!@#$%^&*)</li>
                  </ul>
                </legend>
              </>
            )}
          </div>

          <div className=" hidden w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
            <label
              className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="bonus"
            >
              Codice Sconto:
            </label>
            <input
              id="bonus"
              name="bonus"
              autoComplete="off"
              {...register('bonus', {
                pattern: { value: regextPattern.bonuns, message: t('errors.omitWarning') },
                onChange: (e) => handleChange(e, omitPattern.bonus),
                minLength: { value: 3, message: t('errors.minLength', { min: 3 }) },
                maxLength: { value: 14, message: t('errors.maxLength', { max: 14 }) },
              })}
              placeholder="Codice Sconto"
              className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
            />
            {errors.bonus && <span className="text-red-500 text-xs italic">{errors.bonus.message}</span>}
          </div>
        </div>

        <div className="flex flex-wrap mt-6">
          <div className="w-full px-1 mb-6 mt-2 md:mb-0">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                {...register('companyInfo', { required: false })}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="ml-2 font-semibold">I dati di fatturazione sono differenti</span>
            </label>
          </div>

          {showCompanyInfo && (
            <>
              <div className="w-full mb-6 mt-2 md:mb-0">
                <div className="text-2xl font-semibold">Inserisci i Dati di Fatturazione</div>

                <div className="flex flex-wrap">
                  <div className="w-full px-1 mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="companyName"
                    >
                      Ragione Sociale:
                    </label>
                    <input
                      id="companyName"
                      name="companyName"
                      autoComplete="off"
                      {...register('companyName', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.company, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.company),
                        minLength: { value: 3, message: t('errors.minLength', { min: 3 }) },
                        maxLength: { value: 40, message: t('errors.maxLength', { max: 40 }) },
                      })}
                      placeholder="Ragione Sociale"
                      className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.companyName && showCompanyInfo === true && (
                      <span className="text-red-500 text-xs italic">{errors.companyName.message}</span>
                    )}
                  </div>

                  <div className="w-full sm:w-1/2 px-1 mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="piva"
                    >
                      P.IVA:
                    </label>
                    <input
                      id="piva"
                      name="piva"
                      autoComplete="off"
                      {...register('piva', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.piva, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.piva),
                        minLength: { value: 11, message: t('errors.minLength', { min: 11 }) },
                        maxLength: { value: 11, message: t('errors.maxLength', { max: 11 }) },
                      })}
                      placeholder="P.IVA"
                      className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.piva && showCompanyInfo === true && (
                      <span className="text-red-500 text-xs italic">{errors.piva.message}</span>
                    )}
                  </div>

                  <div className="w-full sm:w-1/2 px-1 mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="fiscalCodeCompany"
                    >
                      Codice Fiscale:
                    </label>
                    <input
                      id="fiscalCodeCompany"
                      name="fiscalCodeCompany"
                      autoComplete="off"
                      {...register('fiscalCodeCompany', {
                        pattern: { value: regextPattern.codiceFiscale, message: t('errors.omitWarning') },
                        onChange: (e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e, omitPattern.codiceFiscale);
                        },
                        minLength: { value: 16, message: t('errors.minLength', { min: 16 }) },
                        maxLength: { value: 16, message: t('errors.maxLength', { max: 16 }) },
                      })}
                      placeholder="Codice Fiscale"
                      className="block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />

                    {errors.fiscalCode && showCompanyInfo === true && (
                      <span className="text-red-500 text-xs italic">{errors.fiscalCode.message}</span>
                    )}
                  </div>

                  <div className="w-full sm:w-1/2 px-1 mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="city"
                    >
                      Città:
                    </label>
                    <input
                      id="city"
                      name="city"
                      autoComplete="off"
                      {...register('city', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.city, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.city),
                        minLength: { value: 2, message: t('errors.minLength', { min: 2 }) },
                        maxLength: { value: 30, message: t('errors.maxLength', { max: 30 }) },
                      })}
                      placeholder="Città"
                      className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.city && showCompanyInfo === true && (
                      <span className="text-red-500 text-xs italic">{errors.city.message}</span>
                    )}
                  </div>

                  <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="province"
                    >
                      Provincia:
                    </label>
                    <input
                      id="province"
                      name="province"
                      autoComplete="off"
                      {...register('province', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.province, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.province),
                        minLength: { value: 2, message: t('errors.minLength', { min: 2 }) },
                        maxLength: { value: 3, message: t('errors.maxLength', { max: 3 }) },
                      })}
                      placeholder="Provincia"
                      className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.province && <span className="text-red-500 text-xs italic">{errors.province.message}</span>}
                  </div>

                  <div className="w-full sm:w-1/2 px-1 mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="address"
                    >
                      Indirizzo:
                    </label>
                    <input
                      id="address"
                      name="address"
                      autoComplete="off"
                      {...register('address', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.address, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.address),
                        minLength: { value: 5, message: t('errors.minLength', { min: 5 }) },
                        maxLength: { value: 80, message: t('errors.maxLength', { max: 80 }) },
                      })}
                      placeholder="Indirizzo"
                      className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.address && showCompanyInfo === true && (
                      <span className="text-red-500 text-xs italic">{errors.address.message}</span>
                    )}
                  </div>

                  <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="houseNumber"
                    >
                      Numero Civico:
                    </label>
                    <input
                      id="houseNumber"
                      name="houseNumber"
                      autoComplete="off"
                      {...register('houseNumber', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.houseNumber, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.houseNumber),
                        maxLength: { value: 6, message: t('errors.maxLength', { max: 6 }) },
                      })}
                      placeholder="numero civico"
                      className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.houseNumber && (
                      <span className="text-red-500 text-xs italic">{errors.houseNumber.message}</span>
                    )}
                  </div>

                  <div className="w-full md:w-1/2 px-1 mb-2 sm:mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="zipcode"
                    >
                      CAP:
                    </label>
                    <input
                      id="zipcode"
                      name="zipcode"
                      autoComplete="off"
                      {...register('zipcode', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.zipCode, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.zipCode),
                        minLength: { value: 5, message: t('errors.minLength', { min: 5 }) },
                        maxLength: { value: 5, message: t('errors.maxLength', { max: 5 }) },
                      })}
                      placeholder="CAP"
                      className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.zipcode && <span className="text-red-500 text-xs italic">{errors.zipcode.message}</span>}
                  </div>

                  <div className="w-full sm:w-1/2 px-1 mb-6 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="pecCompany"
                    >
                      Codice Univoco o PEC:
                    </label>
                    <input
                      id="pecCompany"
                      name="pecCompany"
                      autoComplete="off"
                      {...register('pecCompany', {
                        required: t('errors.requiredField'),
                        //pattern: { value: regextPattern.taxCodeCompany, message: t('errors.uniqueCodeOrPECWarning') },
                        //onChange: (e) => handleChange(e, omitPattern.taxCodeCompany),
                        minLength: { value: 1, message: t('errors.minLength', { min: 1 }) },
                        maxLength: { value: 120, message: t('errors.maxLength', { max: 120 }) },
                      })}
                      placeholder="Codice Univoco o PEC"
                      className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.pecCompany && showCompanyInfo === true && (
                      <span className="text-red-500 text-xs italic">{errors.pecCompany.message}</span>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="flex flex-wrap mt-0 sm:mt-6">
          <div className="w-full px-1 mb-6 mt-2 md:mb-0">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                {...register('termsAndConditions', { required: t('errors.requiredField') })}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="ml-2">Accetto i Termini e Condizioni e l'informativa sulla privacy</span>
            </label>
            {errors.termsAndConditions && (
              <p className="text-red-500 text-xs italic">Devi accettare i Termini e Condizioni per procedere.</p>
            )}
          </div>

          <div className="w-full px-1 mb-6 mt-2 md:mb-0">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                {...register('allowComunication', { required: false })}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="ml-2">Autorizzo all'invio delle comunicazioni prima della scedenza.</span>
            </label>
          </div>
        </div>

        <button
          type="submit"
          className={`w-full mt-6 bg-primaryLabMar font-semibold text-md border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
        >
          REGISTRATI E PROSEGUI
        </button>
      </form>
    </>
  );
}

export default RegistrationForm;
