import React, { useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
import MainFooter from '../components/MainFooter';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, Input } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

const LavoraConNoiView = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();
  const [lastToastId, setLastToastId] = useState(null);

  //--- ---

  //const [user, setUser] = useState(null);
  const birthDayRegex = /^(0[1-9]|[12][0-9]|3[01])(\.|\/)(0[1-9]|1[0-2])(\.|\/)\d{4}$/;
  const codiceFiscaleRegex = /^[a-zA-Z0-9]{16}$/;
  const partitaIvaRegex = /^\d{11}$/;
  const capRegex = /^\d{5}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const numeroCellulareRegex = /^3\d{9}$/;
  const codiceDestinatarioRegex = /^[A-Z0-9]{7}$/;

  //--- ---

  const showToast = (message, mode, timer = 5000) => {
    if (lastToastId) {
      toast.dismiss(lastToastId);
    }
    let id = 0;
    if (mode === 'error') {
      id = toast.error(message, {
        duration: timer,
        style: {
          borderRadius: '10px',
          background: '#f44336',
          color: '#fff',
          padding: '12px 24px',
        },
      });
    }
    if (mode === 'success') {
      id = toast.success(message, {
        duration: timer,
        style: {
          borderRadius: '10px',
          background: '#026300',
          color: '#fff',
          padding: '12px 24px',
        },
      });
    }
    setLastToastId(id);
  }; //showToast;

  const {
    register,
    setValue,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    // },
  });

  //-- on submit form
  const onSubmit = (data) => {
    const params = {
      regimeFiscale: data?.tutorType,
      ragioneSociale: data?.ragioneSociale,
      rappresentateLegale: data?.rappresentateLegale,
      luogoNascita: data?.luogoNascita,
      dataNascita: data?.dataNascita,
      codiceFiscale: data?.codiceFiscale,
      partitaIVA: data?.partitaIVA,
      capSedeLegale: data?.capSedeLegale,
      cittaSedeLegale: data?.cittaSedeLegale,
      indirizzoSedeLegale: data?.indirizzoSedeLegale,
      capSedeOperativa: data?.capSedeOperativa,
      cittaSedeOperativa: data?.cittaSedeOperativa,
      indirizzoSedeOperativa: data?.indirizzoSedeOperativa,
      email: data?.email,
      password: data?.password,
      codiceUnivoco: data?.codiceUnivoco,
      pec: data?.pec,
      telefono: data?.telefono,
    };

    //--- submit ---
    const url = `https://app.labmar.it/backend/v1/dashboard/tutor/joinrequest`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };

    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          showToast(
            'Si è verificato un errore momentaneo. Prova ad inviare nuovamente i dati inseriti.',
            'error',
            5000
          );
          return false;
        }
        return response.json();
      })
      .then((data) => {
        // Gestisci la risposta JSON
        if (data && data?.status === 200) {
          showToast(
            'Ti ringraziamo per la tua richiesta. riceverai a breve informazioni ed aggiornamenti',
            'success',
            5000
          );
          setTimeout(() => {
            window.location.href = `/`;
          }, 5000);
          return false;
        }
      })
      .catch((error) => {
        // Gestisci gli errori
        console.log(error);
      });
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);
  }, []);

  //--- ---

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HACCP | Lavora con noi - Corsi haccp online con attestato riconosciuto</title>
        <meta
          name="description"
          content="Ottieni l'attestato haccp ex libretto sanitario con i corsi di alimentarista validi a norma di legge perché riconosciuti a livello europeo come da D.Lgs."
        />
      </Helmet>

      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader currentDomain={currentDomain} />

      <div className="container-fluid p-0 bg-white">
        <div className="container mx-auto py-9 px-6 p-0 border-bottom text-primaryLabMar text-md">
          <div className="w-full mb-4 font-bold text-left text-3xl">LAVORA CON NOI</div>

          <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '1024px', margin: '0 auto' }}>
            <div className="max-w-screen-md mx-auto">
              <div className="mb-5">
                Diventa un nostro Tutor e amplia la tua offerta con i corsi di formazione HACCP.
                <br />
                <br /> Offri ai tuoi clienti un servizio certificato, con il supporto di una piattaforma semplice ed
                efficace. Unisciti a noi e fai crescere il tuo business!
                <br />
                Per un primo contatto, abbiamo bisogno di alcune informazione. ompila il form sottostante e sarai ricontattato al più presto.
              </div>

              {/** */}
              <div className="flex flex-wrap md:flex-nowrap md:gap-2">
                <div className="w-full md:w-1/2 mb-2 select-none">
                  <Input
                    placeholder="Ragione Sociale"
                    type="text"
                    className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    {...register('ragioneSociale', { required: true })}
                  />
                  {errors?.ragioneSociale && <span className="text-red mb-4 text-sm">Inserisci Ragione Sociale</span>}
                </div>

                <div className="w-full md:w-1/2 mb-2 select-none">
                  <Input
                    placeholder="Rappresentante Legale"
                    type="text"
                    className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    {...register('rappresentateLegale', { required: false })}
                  />
                  {errors?.rappresentateLegale && (
                    <span className="text-red mb-4 text-sm">Inserisci Rappresentate Legale</span>
                  )}
                </div>
              </div>

              {/** 
              <div className="flex flex-wrap md:flex-nowrap md:gap-2">
                <div className="w-full md:w-1/2 mb-2 select-none">
                  <Input
                    placeholder="Luogo di Nasciata"
                    type="text"
                    className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    {...register('luogoNascita', { required: true })}
                  />
                  {errors?.luogoNascita && <span className="text-red mb-4 text-sm">Inserisci Luogo di Nascita</span>}
                </div>

                <div className="w-full md:w-1/2 mb-2 select-none">
                  <Input
                    placeholder="Data di Nasciata (gg/mm/aaaa)"
                    type="text"
                    className="appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    {...register('dataNascita', { required: true, pattern: birthDayRegex })}
                  />
                  {errors?.dataNascita && (
                    <span className="text-red mb-4 text-sm">Inserisci Data di Nascita nel formato gg/mm/aaaa</span>
                  )}
                </div>
              </div>*/}

              {/** */}
              <div className="flex flex-wrap md:flex-nowrap md:gap-2">
                <div className="w-full md:w-1/2 mb-2 select-none">
                  <Input
                    placeholder="Codice Fiscale"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('codiceFiscale', { required: true, pattern: codiceFiscaleRegex })}
                  />
                  {errors?.codiceFiscale && <span className="text-red mb-4 text-sm">Inserisci il Codice Fiscale</span>}
                </div>

                <div className="w-full md:w-1/2 mb-2 select-none">
                  <Input
                    placeholder="Partita IVA"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('partitaIVA', { required: true, pattern: partitaIvaRegex })}
                  />
                  {errors?.partitaIVA && <span className="text-red mb-4 text-sm">Inserisci la Partita IVA</span>}
                </div>
              </div>

              {/** */}
              <div className="flex flex-wrap md:flex-nowrap md:gap-2">
                <div className="w-full md:w-1/3 mt-2 mb-2 select-none">
                  <Input
                    placeholder="CAP (sede legale)"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('capSedeLegale', { required: true, pattern: capRegex })}
                  />
                  {errors?.capSedeLegale && (
                    <span className="text-red mb-4 text-sm">Inserisci il CAP della Sede Legale</span>
                  )}
                </div>

                <div className="w-full md:w-1/3 mt-2 mb-2 select-none">
                  <Input
                    placeholder="Città (sede legale)"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('cittaSedeLegale', { required: true })}
                  />
                  {errors?.cittaSedeLegale && (
                    <span className="text-red mb-4 text-sm">Inserisci la Città della Sede Legale</span>
                  )}
                </div>

                <div className="w-full md:w-1/3 mt-2 mb-2 select-none">
                  <Input
                    placeholder="Indirizzo (sede legale)"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('indirizzoSedeLegale', { required: true })}
                  />
                  {errors?.indirizzoSedeLegale && (
                    <span className="text-red mb-4 text-sm">{`Inserisci l'indirizzo della Sede Legale`}</span>
                  )}
                </div>
              </div>

              {/** 
              <div className="flex flex-wrap md:flex-nowrap md:gap-2">
                <div className="w-full md:w-1/3 mb-2 select-none">
                  <Input
                    placeholder="CAP (sede operativa)"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('capSedeOperativa', { required: false, pattern: capRegex })}
                  />
                  {errors?.capSedeOperativa && (
                    <span className="text-red mb-4 text-sm">Inserisci il CAP della Sede Operativa</span>
                  )}
                </div>

                <div className="w-full md:w-1/3 mb-2 select-none">
                  <Input
                    placeholder="Città (sede operativa)"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('cittaSedeOperativa', { required: false })}
                  />
                  {errors?.cittaSedeOperativa && (
                    <span className="text-red mb-4 text-sm">Inserisci la Città della Sede Operativa</span>
                  )}
                </div>

                <div className="w-full md:w-1/3 mb-2 select-none">
                  <Input
                    placeholder="Indirizzo (sede operativa)"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('indirizzoSedeOperativa', { required: false })}
                  />
                  {errors?.indirizzoSedeOperativa && (
                    <span className="text-red mb-4 text-sm">{`Inserisci l'indirizzo della Sede Operativa`}</span>
                  )}
                </div>
              </div>*/}

              {/** */}
              <div className="flex flex-wrap md:flex-nowrap md:gap-2">
                <div className="w-full md:w-full mb-2 select-none">
                  <Input
                    placeholder="Email"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('email', { required: true, pattern: emailRegex })}
                  />
                  {errors?.email && <span className="text-red mb-4 text-sm">{`Inserisci l'email`}</span>}
                </div>

                {/*<div className="w-full md:w-1/3 mb-2 select-none">
                  <Input
                    placeholder="password"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('password', { required: true })}
                  />
                  {errors?.password && <span className="text-red mb-4 text-sm">Inserisci la password</span>}
                </div>

                <div className="w-full md:w-1/3 mb-2 select-none">
                  <Input
                    placeholder="Codice Univoco"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('codiceUnivoco', { required: false, pattern: codiceDestinatarioRegex })}
                  />
                  {errors?.codiceUnivoco && <span className="text-red mb-4 text-sm">Inserisci il Codice Univoco</span>}
                </div>*/}
              </div>

              {/** 
              <div className="flex flex-wrap md:flex-nowrap md:gap-2">
                <div className="w-full md:w-1/2 mb-2 select-none">
                  <Input
                    placeholder="PEC"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('pec', { required: true, pattern: emailRegex })}
                  />
                  {errors?.pec && <span className="text-red mb-4 text-sm">Inserisci la PEC</span>}
                </div>

                <div className="w-full md:w-1/2 mb-2 select-none">
                  <Input
                    placeholder="telefono"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...register('telefono', { required: true, pattern: numeroCellulareRegex })}
                  />
                  {errors?.telefono && <span className="text-red mb-4 text-sm">Inserisci il numero di Telefono</span>}
                </div>
              </div>*/}

              {/** */}
              <div className="flex flex-wrap md:flex-nowrap md:gap-2">
                <div className="flex flex-wrap ml-2 radioStyle">
                  <Checkbox
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    {...register('accepted', { required: true })}
                  />
                </div>
                <p className="ml-2 text-sm">
                  Dichiaro di aver letto la{' '}
                  <span className="font-bold underline cursor-pointer">
                    <Link to="/privacy-policy" className="font-semibold" target={'_blank'} rel="noopener noreferrer">
                      Privacy Policy
                    </Link>
                  </span>{' '}
                  ai sensi del Regolamento UE 2016/679 (GDPR) e le{' '}
                  <span className="font-bold underline cursor-pointer">
                    <Link to="/condizioni-uso" className="font-semibold" target={'_blank'} rel="noopener noreferrer">
                      condizioni generali di utilizzo del servizio
                    </Link>
                  </span>
                  {errors.accepted && <p className="text-red-900">Conferma per continuare</p>}
                </p>
              </div>

              <div className="flex flex-wrap justify-center mt-8">
                <Button
                  type="submit"
                  className="w-full mt-6 bg-primaryLabMar font-semibold text-md border border-transparent text-white p-4 py-4 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar"
                >
                  Invia Richiesta
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default LavoraConNoiView;
