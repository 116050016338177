import React, { useEffect } from 'react';
import LoaderFullSwcreen from '../LoaderFullSwcreen';
import toast from 'react-hot-toast';

const CartDetail = (prop) => {
  const { paymentMethods, go2gateway, setForceRefresh, setCurrentGateway } = prop;

  const [cartData, setCartData] = React.useState(null);
  const [showLoader, setShowLoader] = React.useState(false);

  const discountCodeRef = React.useRef(null);

  const setDiscountCode = () => {
    if (discountCodeRef.current.value === '') {
      return true;
    }

    setCurrentGateway(); //reset gateway
    setShowLoader(true);

    fetch(`${process.env.REACT_APP_API_V2_URL}/cart/load-promo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        discountCode: discountCodeRef.current.value,
        token: sessionStorage.getItem('token'),
        domain: window.location.origin.replace(/^https?:\/\//, ''),
        registrationCode: cartData?.registrationCode || null,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.payload.status === 'success') {
          toast.success('Il codice sconto è stato applicato correttamente.');

          cartData.priceDiscount = data?.payload?.amount || data.cartData.priceDiscount;
          cartData.usedBonus = discountCodeRef?.current?.value || null;

          setCartData(cartData);
          setForceRefresh(Math.random());

          setTimeout(() => {
            setShowLoader(false);
          }, 3800);
        }

        if (data?.payload.status !== 'success') {
          toast.error('Si è verificato un errore. Se il problema persiste contatta il supporto.');
          setTimeout(() => {
            setShowLoader(false);
            discountCodeRef.current.value = '';
          }, 3800);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Si è verificato un errore. Se il problema persiste contatta il supporto.');
        setTimeout(() => {
          setShowLoader(false);
          discountCodeRef.current.value = '';
        }, 3800);
      });
  }; //setDiscountCode

  useEffect(() => {
    setCartData(prop.cartData);
  }, [prop.cartData]);

  //lastIsActive = cartData?.cartData[0]?.isActive || 0;

  //--- ---

  return (
    <>
      {showLoader && <LoaderFullSwcreen message={'Verifica Codice Sconto in Corso...'} />}

      <section className="container-fluid p-0 m-0">
        <div className="container mx-auto max-w-1xl p-0 border-bottom">
          {/** note next exipred certificate */}
          <div className={`hidden text-xl font-semibold mb-4 mt-1 text-grey-800`}>
            Avviso scadenza certificato HACCP.
            <br />
            <span className="text-lg font-medium text-grey-800">
              Il certificato n° scadrà il 31/12/2022. Rinnova ora il tuo corso HACCP per continuare a lavorare in
              sicurezza.
            </span>
          </div>

          <div className="text-xl font-semibold mb-4 mt-1 text-grey-800">
            Ordine in attesa di pagamento.
            <br />
            <span className="text-lg font-medium text-grey-800">
              Scegli una modalità di pagamento qui sotto indicate:
            </span>
            <div className="mt-3">
              <div className="text-2xl font-semibold">
                <span className={`${(cartData?.usedBonus !== null && cartData?.usedBonus !== '') && 'line-through'}`}>
                  da pagare: € {(parseFloat(cartData?.price) > 0 ? parseFloat(cartData?.price) : 0).toFixed(2)}
                </span>
              </div>

              {(cartData?.usedBonus !== null && cartData?.usedBonus !== '') && (
                <div className="text-md font-normal mt-1 text-red-900">
                  da pagare con Promo: €{' '}
                  {(parseFloat(cartData?.priceDiscount) > 0 ? parseFloat(cartData?.priceDiscount) : 0).toFixed(2)}
                </div>
              )}

              {(cartData?.usedBonus !== null && cartData?.usedBonus !== '') && (
                <div className="mt-0 mb-2 text-lg font-normal text-red-900">
                  stai utilizzando un codice promo: <b>{cartData?.usedBonus}</b>
                </div>
              )}

              <p className="text-lg font-normal">
                <span className="font-semibold">Descrizione:</span> {cartData?.description || ''}
              </p>
              <p className="text-lg font-normal hidden">
                <span className="font-semibold">Registrazione:</span> {cartData?.registrationCode || ''}
              </p>
            </div>
            {(cartData?.usedBonus === null || cartData?.usedBonus === '') && (
              <div className="w-full md:w-1/3 mb-2 mt-4 sm:mb-6 md:mb-0">
                <label
                  className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Codice Sconto:
                </label>
                <input
                  ref={discountCodeRef}
                  name="bonus"
                  placeholder="Se hai un Codice Sconto applicalo adesso"
                  className="appearance-none block w-full text-blue-gray-700 border border-primaryLabMar rounded-lg py-2 px-2 text-md"
                />

                <button
                  type="button"
                  className={`w-full mt-2 bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                  onClick={() => setDiscountCode()}
                >
                  APPLICA SCONTO
                </button>
              </div>
            )}
          </div>

          {/** metodi di pagamento */}
          <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mx-auto w-full my-10 mt-8 mb-0">
            {paymentMethods
              .filter((item) => {
                return item.isActive === true;
              })
              .map((paymentMethod, index) => (
                <li
                  key={index}
                  className={paymentMethod.className}
                  onClick={() => go2gateway(cartData.id, paymentMethod.gateway)}
                >
                  <p className="text-xl font-bold uppercase mb-2">Clicca</p>
                  <img src={paymentMethod.image} alt={paymentMethod.name} className="w-1/2 mx-auto max-w-28 mb-2" />
                  <p className="text-sm font-medium w-full text-center">{paymentMethod.description}</p>
                </li>
              ))}
          </ul>

          <div id="pinScrollForMobile" className="w-full text-md font-semibold mt-6 text-right text-red-900">
            Riceverai l'attestato entro 48 ore da quando completerai il corso.
          </div>
        </div>
      </section>
    </>
  );
};

export default CartDetail;
