import React, { useEffect, useState } from 'react';
import { add2Cart } from '../libs/utils';

const MainChooseCourse = (prop) => {
  const [courses, setCourses] = useState([]);

  const loadRemote = () => {
    try {
      fetch(`${process.env.REACT_APP_API_V1_URL}/trainingOffer/courses`)
        .then((response) => response.json())
        .then((data) => {
          const courses = data?.payload?.courses || [];

          Promise.all(courses.map((course) => getPriceByCourseCode(course.code)))
            .then((prices) => {
              const updatedCourses = courses.map((course, index) => {
                course.price = prices[index];
                return course;
              });
              setCourses(updatedCourses);
            })
            .catch((error) => {
              console.error('Errore nel recuperare il prezzo:', error);
            });
        })
        .catch((error) => {
          console.error('Errore nel recuperare i corsi:', error);
        });
    } catch (error) {
      console.error('Error:', error);
      // toast.error("This didn't work.")
    }
  };

  const getPriceByCourseCode = (courseCode = '') => {
    return new Promise((resolve, reject) => {
      let currentDomain = window.location.origin.replace(/^https?:\/\//, '');
      if(prop?.currentDomain) {
        currentDomain = prop.currentDomain;
      }

      const externalEndpoint = process.env.REACT_APP_API_V1_URL + '/dashboard/pricelist/';
      const urlWithDomainParam = `${externalEndpoint}?domain=${encodeURIComponent(currentDomain)}`;

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(urlWithDomainParam, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Errore nella richiesta Fetch');
          }
          return response.json();
        })
        .then((data) => {
          const priceList = (data?.payload?.data || []).filter((item) => item.courseCode === courseCode);

          let price = 0;
          let iva = 0;

          if (priceList[0]?.price) {
            price = parseFloat(priceList[0]?.price) > 0 ? parseFloat(priceList[0]?.price) : 0;
          } else {
            price = parseFloat(priceList[0]?.priceCourse) > 0 ? parseFloat(priceList[0]?.priceCourse) : 0;
          }

          iva = 0; //(price * parseFloat(priceList[0]?.iva)) / 100;
          price = parseFloat(price + iva).toFixed(2);

          resolve(price);
        })
        .catch((error) => {
          console.error('Si è verificato un errore:', error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    loadRemote();
  }, []); //useEffect

  useEffect(() => {
    //eslint-disable-next-line
  }, [courses]); //useEffect

  //--- ---

  return (
    <>
      <section id="corsi">
        <div className="container-fluid p-0 bg-primaryLabMar">
          <div className="container mx-auto py-9 px-6 p-0 border-bottom text-white">
            <div className="w-full mb-6 font-bold text-center text-4xl">Scegli i tuoi corsi</div>

            {courses.map((course, index) => {
              let image = 'uno.jpg';
              let programma = '/programma-corso-haccp-alimentarista-12-ore-categoria-c';
              if (index % 2 === 0) {
                image = 'uno.jpg';
                programma = '/programma-corso-haccp-alimentarista-12-ore-categoria-c';
              } else {
                image = 'due.jpg';
                programma = '/programma-corso-haccp-alimentarista-6-ore-categoria-b';
              }

              return (
                <div key={index} className="mx-auto flex justify-center my-12">
                  <div className="w-full p-4 py-5 sm:p-8 sm:py-10 bg-white text-primaryLabMar rounded-2xl">
                    <ul
                      className={`flex ${
                        index % 2 === 0 ? 'flex-col sm:flex-row' : 'flex-col sm:flex-row-reverse'
                      } gap-8`}
                    >
                      <li className="w-full hidden sm:w-1/2 sm:block">
                        <img
                          src={process.env.PUBLIC_URL + '/assets/' + image}
                          alt="Corso HACCP 12 ore"
                          className="mx-auto mb-0 sm:mb-8 rounded-xl"
                        />
                      </li>
                      <li className="w-full sm:w-1/2">
                        {/*<div className="w-full mb-2 font-medium text-2xl">{course.title}</div>*/}
                        <div className="w-full mb-4 font-bold text-2xl">{course.description}</div>
                        <div className="text-lg mb-6 font-normal text-left">{course.subTitle}</div>
                        <div className="w-full mb-6 font-medium text-4xl">€ {parseFloat(course.price).toFixed(2)}</div>
                        <ul className="text-md leading-6 text-lg mb-6">
                          <li className="mb-2 font-semibold">Incluso nel prezzo:</li>
                          <li>&#8226; Materiale didattico</li>
                          <li>&#8226; Utilizzo della piattaforma</li>
                          <li>&#8226; Tutor dedicato</li>
                          <li>&#8226; Attestato</li>
                        </ul>

                        {!sessionStorage.getItem('token') ? (
                          <div className="my-6 flex flex-col gap-0">
                            <div className="flex w-full gap-5">
                              <a
                                href={`/registrati/?courseCode=${course.code}&domain=${prop.currentDomain}`}
                                className="mt-4 w-1/2 px-2 py-2 rounded-full text-center font-semibold text-md bg-white text-primaryLabMar border-2 border-primaryLabMar transition-colors duration-300 hover:bg-primaryLabMar hover:text-white hover:border-transparent"
                              >
                                REGISTRATI
                              </a>
                              <a
                                href={`/accedi?domain=${prop.currentDomain}`}
                                className="mt-4 w-1/2 px-2 py-2 rounded-full text-center font-semibold text-md bg-white text-primaryLabMar border-2 border-primaryLabMar transition-colors duration-300 hover:bg-primaryLabMar hover:text-white hover:border-transparent"
                              >
                                ACCEDI
                              </a>
                            </div>
                            <a
                              href={programma}
                              target="_self"
                              className="mt-4 w-full px-4 py-4 rounded-full font-semibold text-md text-center bg-primaryLabMar text-white border-2 border-primaryLabMar transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border-primaryLabMar"
                            >
                              mostra il programma del corso
                            </a>
                          </div>
                        ) : (
                          <div className="my-6 flex flex-col gap-0">
                            <div className="flex w-full gap-5">
                              <button
                                onClick={() => {
                                  add2Cart(course.code, prop.currentDomain, sessionStorage.getItem('token'));
                                }}
                                className="mt-6 w-full px-6 py-3 rounded-full text-center font-semibold text-md bg-white text-primaryLabMar border-2 border-primaryLabMar transition-colors duration-300 hover:bg-primaryLabMar hover:text-white hover:border-transparent"
                              >
                                AGGIUNGI AI TUOI ORDINI
                              </button>
                            </div>
                          </div>
                        )}
                      </li>
                    </ul>

                    <div className="w-full text-md leading-6">
                      <ul className="text-md leading-6 text-md">
                        <li className="font-semibold">Sono obbligati per legge a fare il corso:</li>
                        <li>
                          &#8226;{' '}
                          <i>
                            Operatori che non manipolano alimenti ( camerieri, promoter, magazzinieri, addetti a vendita
                            e trasporto di alimenti )
                          </i>
                        </li>
                        <li className="">
                          &#8226;{' '}
                          <i>
                            Operatori che manipolano alimenti ( cuochi, aiuto cuochi, pizzaioli, pasticceri, panettieri,
                            barman, etc )
                          </i>
                        </li>
                        <li>
                          &#8226;{' '}
                          <i>
                            Titolari e responsabili delle industrie alimentari (Titolari di attività del settore
                            alimentare o responsabili incaricati )
                          </i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}

            <p className="w-full sm:w-2/3 mx-auto font-semibold text-2xl leading-8 text-center">
              Tutti i nostri attestati sono validi e riconosciuti in Italia e Europa e ti ricordiamo che gli attestati
              sono obbligatori per tutte quelle persone che operano nel settore alimentare.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainChooseCourse;
