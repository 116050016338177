import React from 'react';
import moment from 'moment';

const CourseCalendar = ({ mode = 'long' }) => {
  const currentMonthStart = moment().startOf('month');
  const currentMonthEnd = moment().endOf('month');
  const now = moment();

  // Definizione delle fasce temporali
  const firstRangeStart = currentMonthStart;
  const firstRangeEnd = moment(firstRangeStart).add(9, 'days');

  const secondRangeStart = moment(firstRangeEnd).add(1, 'day');
  const secondRangeEnd = moment(firstRangeStart).add(19, 'days');

  const thirdRangeStart = moment(secondRangeEnd).add(1, 'day');
  const thirdRangeEnd = currentMonthEnd;

  // Creazione delle fasce di date per l'output
  const dateRanges = [
    { label: 'Fascia 1', start: firstRangeStart, end: firstRangeEnd },
    { label: 'Fascia 2', start: secondRangeStart, end: secondRangeEnd },
    { label: 'Fascia 3', start: thirdRangeStart, end: thirdRangeEnd },
  ];

  // Determina se now è compreso in una fascia
  const states = dateRanges.map(({ start, end }) =>
    now.isBetween(start, end, 'day', '[]')
  );

  return (
    <div id="home" className="container-fluid p-0">
      <div className="container mx-auto max-w-1xl">
        <table className={`table-auto w-full ${mode === 'short' && 'text-sm'}`}>
          <thead>
            <tr className="border border-gray-600 bg-primaryLabMar text-white">
              <th className="border p-1 text-center border-gray-600">CORSO</th>
              <th className="border p-1 text-center border-gray-600">DATE</th>
              <th className="border p-1 text-center border-gray-600">STATO</th>
            </tr>
          </thead>
          <tbody>
            {dateRanges.map(({ start, end }, index) => (
              <>
                <tr key={`12ore-${index}`} className="bg-gray-100">
                  <td className="border border-gray-600 p-1">
                    Corso Formazione Alimentarista 12 ore
                  </td>
                  <td className="border border-gray-600 p-1 text-center">
                    {`dal ${start.format('DD/MM/YYYY')} al ${end.format('DD/MM/YYYY')}`}
                  </td>
                  <td
                    className={`border border-gray-600 p-1 text-center font-semibold ${
                      states[index] ? 'text-green-900' : 'text-red-900'
                    }`}
                  >
                    {states[index] ? 'APERTO' : 'CHIUSO'}
                  </td>
                </tr>
                <tr key={`6ore-${index}`} className="bg-gray-100">
                  <td className="border border-gray-600 p-1">
                    Corso Formazione Alimentarista 6 ore
                  </td>
                  <td className="border border-gray-600 p-1 text-center">
                    {`dal ${start.format('DD/MM/YYYY')} al ${end.format('DD/MM/YYYY')}`}
                  </td>
                  <td
                    className={`border border-gray-600 p-1 text-center font-semibold ${
                      states[index] ? 'text-green-900' : 'text-red-900'
                    }`}
                  >
                    {states[index] ? 'APERTO' : 'CHIUSO'}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3} className="border border-gray-600 bg-primaryLabMar"></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default CourseCalendar;
