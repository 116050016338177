import React, { useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
import MainFooter from '../components/MainFooter';
import RegistrationForm from '../components/RegistrationForm';
import ChooseCourses from '../components/ChooseCourses';
import { Helmet } from 'react-helmet';
import CourseCalendar from '../components/CourseCalendar';

const RegisterView = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();
  const [courseCode, setCourseCode] = useState('');
  const [courses, setCourses] = useState([]);
  const [isSuccessSubmit, setSuccessSubmit] = useState(false);

  const loadRemote = () => {
    try {
      fetch(`${process.env.REACT_APP_API_V1_URL}/trainingOffer/courses`)
        .then((response) => response.json())
        .then((data) => {
          const courses = data?.payload?.courses || [];

          Promise.all(courses.map((course) => getPriceByCourseCode(course.code)))
            .then((prices) => {
              const updatedCourses = courses.map((course, index) => {
                course.price = prices[index];
                return course;
              });
              setCourses(updatedCourses);
            })
            .catch((error) => {
              console.error('Errore nel recuperare il prezzo:', error);
            });
        })
        .catch((error) => {
          console.error('Errore nel recuperare i corsi:', error);
        });
    } catch (error) {
      console.error('Error:', error);
      // toast.error("This didn't work.")
    }
  };

  const getPriceByCourseCode = (courseCode = '') => {
    return new Promise((resolve, reject) => {
      let currentDomain = window.location.origin.replace(/^https?:\/\//, '');
      if (props?.currentDomain) {
        currentDomain = props.currentDomain;
      }

      const externalEndpoint = process.env.REACT_APP_API_V1_URL + '/dashboard/pricelist/';
      const urlWithDomainParam = `${externalEndpoint}?domain=${encodeURIComponent(currentDomain)}`;

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(urlWithDomainParam, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Errore nella richiesta Fetch');
          }
          return response.json();
        })
        .then((data) => {
          const priceList = (data?.payload?.data || []).filter((item) => item.courseCode === courseCode);

          let price = 0;
          let iva = 0;

          if (priceList[0]?.price) {
            price = parseFloat(priceList[0]?.price) > 0 ? parseFloat(priceList[0]?.price) : 0;
          } else {
            price = parseFloat(priceList[0]?.priceCourse) > 0 ? parseFloat(priceList[0]?.priceCourse) : 0;
          }

          iva = 0; //(price * parseFloat(priceList[0]?.iva)) / 100;
          price = parseFloat(price + iva).toFixed(2);

          resolve(price);
        })
        .catch((error) => {
          console.error('Si è verificato un errore:', error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const courseCode = params.get('courseCode') || '';
    setCourseCode(courseCode);

    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);

    if (courseCode === '') {
      loadRemote();
    }
  }, []);

  useEffect(() => {
    if (isSuccessSubmit) {
      window.location.href = '/myprofile';
      /*setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        loadLastOrder();
      }, 300);*/
    }
  }, [isSuccessSubmit]);

  //--- ---

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HACCP | Corsi haccp online con attestato riconosciuto</title>
        <meta
          name="description"
          content="Ottieni l'attestato haccp ex libretto sanitario con i corsi di alimentarista validi a norma di legge perché riconosciuti a livello europeo come da D.Lgs."
        />
      </Helmet>

      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader currentDomain={currentDomain} />

      <div className="container-fluid p-0 bg-white">
        <div className="container w-full mx-auto py-9 px-3 sm:px-6 p-0 border-bottom text-primaryLabMar text-md">
          <ul className="flex flex-col sm:flex-row gap-6">
            <li className="w-full p-4 sm:w-2/3">
              {courseCode !== '' && !isSuccessSubmit && (
                <div>
                  <section className="bg-white border-2 border-deep-orange-800 rounded-xl px-4 py-4 w-full mb-6">
                    <p className="font-normal text-gray-800 text-lg mb-4">
                      Se hai già effettuato la registrazione o se hai già un account accedi direttamente alla tua area
                      riservata.
                    </p>
                    <a
                      href={`/accedi?domain=${currentDomain}`}
                      className="bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar"
                    >
                      ACCEDI
                    </a>
                  </section>

                  <div className="text-2xl font-semibold">Registrati al corso</div>
                  <div className="text-lg font-regular mt-2">Dati del corsista</div>
                  <RegistrationForm
                    courseCode={courseCode}
                    currentDomain={currentDomain}
                    setSuccessSubmit={setSuccessSubmit}
                  />
                </div>
              )}

              {/*isSuccessSubmit && (
                <div>
                  <div className="text-3xl font-semibold">Congratulazioni! La tua registrazione è avvenuta con successo</div>
                  <div className="text-lg font-regular mt-2">ecco il riepilogo dei tuoi dati:</div>
                  <SuccessRegistration />
                </div>
              )*/}

              {courseCode === '' && <ChooseCourses courses={courses} currentDomain={currentDomain} />}
            </li>
            <li className="w-full sm:w-1/3 p-4 border-0 sm:border-l-4 border-l-primaryLabMar">
              <div>
                <div className="text-2xl font-semibold">Informazioni corso</div>
                <ul className="w-full my-4 text-md list-disc list-inside">
                  <li>Corso haccp online in e-learning</li>
                  {courseCode !== '' && <li>Durata minima {courseCode === 'haccp12' ? '12 ore' : '6 ore'}</li>}
                  <li>Accesso alla piattaforma gratuita</li>
                  <li>Materiale didattico scaricabile</li>
                  <li>Attestato valido in tutta Italia</li>
                  <li>Aggiornamento obbligatorio ogni 3 anni</li>
                  <li>Tutor personale garantito</li>
                </ul>
              </div>

              <div className="mt-8">
                <div className="text-2xl font-semibold">Valido ai sensi delle seguenti normative</div>
                <ul className="w-full my-4 text-md list-disc list-inside">
                  <li>Decreto assessoriale Regione Sicilia n.698 del 3 Agosto 2022</li>
                  <li>Regolamento Europeo (CE) 852-853/2004</li>
                  <li>Regolamento europeo (CE) 178/02</li>
                  <li>
                    Il corso è inoltre valido in tutta Italia e in Europa ai sensi della direttiva Europea 2005/36/CE
                    Recepita dallo stato italiano con Dlgs 206/2007
                  </li>
                </ul>
              </div>

              <div className="mt-8 py-3 border-y-2 border-x-gray-900">
                <div className="text-2xl font-semibold mb-2">Calendario Corsi:</div>
                <CourseCalendar mode={'short'} />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default RegisterView;
