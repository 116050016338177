import React, { Suspense, useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
//import MainHero from '../components/MainHero';
import MainHowToGetTitle from '../components/MainHowToGetTitle';
import MainWhyChoose from '../components/MainWhyChoose';
import MainChooseCourse from '../components/MainChooseCourse';
import MainReviews from '../components/MainReviews';
//import MainStartNow from '../components/MainStartNow';
import MainFooter from '../components/MainFooter';
import MainHeroShort from '../components/MainHeroShort';
import MainStartNowMini from '../components/MainStartNowMini';
import { Helmet } from 'react-helmet';

const HomeView = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();

  const handleAnchorClick = (event, anchor) => {
    event.preventDefault();
    if (document.getElementById(anchor)) {
      //document.getElementById(anchor).scrollIntoView({ behavior: 'smooth' });
      const element = document.getElementById(anchor);
      const offset = 118;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HACCP | Corsi haccp online con attestato riconosciuto</title>
        <meta
          name="description"
          content="Ottieni l'attestato haccp ex libretto sanitario con i corsi di alimentarista validi a norma di legge perché riconosciuti a livello europeo come da D.Lgs."
        />
      </Helmet>

      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader handleAnchorClick={handleAnchorClick} currentDomain={currentDomain} />
      {/*<MainHero currentDomain={currentDomain} />*/}
      <MainHeroShort currentDomain={currentDomain} />

      <Suspense>
        <MainStartNowMini currentDomain={currentDomain} />
      </Suspense>

      <MainHowToGetTitle />

      <Suspense>
        <MainChooseCourse currentDomain={currentDomain} />
      </Suspense>

      <MainWhyChoose />
      <MainReviews />

      <MainFooter handleAnchorClick={handleAnchorClick} />
    </>
  );
};

export default HomeView;
