import React from 'react';
//import toast from 'react-hot-toast';
import { RiCloseCircleFill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';

const MainMenuHeader = (prop) => {
  const [showMenuMobile, setShowMenuMobile] = React.useState(false);
  //const [lastToastId, setLastToastId] = React.useState(null);

  const location = useLocation();
  const token = sessionStorage.getItem('token') || undefined;
  //const courseCode = new URLSearchParams(location.search).get('courseCode');

  const mainLinkDesktop = [
    'Corsi',
    'Attestati',
    'Perchè sceglierci',
    'Lavora con noi',
    'REGISTRATI',
    'ACCEDI',
    'IL MIO PROFILO',
    'LOGOUT',
  ];
  const mainLinkMobile = [
    'Corsi',
    'Attestati',
    'Perchè sceglierci',
    'Lavora con noi',
    'Registrati',
    'Accedi',
    'Il mio profilo',
    'Logout',
    'Home',
  ];
  const omitLink = [];

  //--- ---

  const handleToggle = () => {
    if (!showMenuMobile === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    setShowMenuMobile(!showMenuMobile);
  };

  const getState = (index) => {
    let result = false;

    if (!token && [6, 7].includes(index)) {
      result = true;
    }

    if (token && [1, 2, 3, 4, 5].includes(index)) {
      result = true;
    }

    return result;
  };

  /*const showToast = (message, mode, timer = 5000) => {
    if (lastToastId) {
      toast.dismiss(lastToastId);
    }
    let id = 0;
    if (mode === 'error') {
      id = toast.error(message, {
        duration: timer,
        style: {
          borderRadius: '10px',
          background: '#f44336',
          color: '#fff',
          padding: '12px 24px',
        },
      });
    }
    setLastToastId(id);
  }; //showToast;*/

  const logout = () => {
    const token = sessionStorage.getItem('token');
    const obj2Send = { token: token };

    try {
      fetch(`${process.env.REACT_APP_API_V2_URL}/account/logout`, {
        method: 'POST',
        body: JSON.stringify(obj2Send),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          /*if (data.status !== 200) {
            showToast(data.message, 'error', 5000);
            return true;
          }*/

          sessionStorage.removeItem('token');
          setTimeout(() => {
            window.location.href = '/';
          }, 500);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //--- ---

  return (
    <>
      <nav id="home" className="container-fluid p-0 bg-orange-50 sticky top-0 z-40">
        <div className="container mx-auto max-w-1xl py-3 border-bottom">
          <div className="flex flex-row justify-start sm:justify-center items-stretch pl-5">
            <a href="/" target="_self" className="w-1/2">
              <div className="text-left text-primaryLabMar font-sans gap-3 text-2xl flex flex-row items-center">
                <img style={{ width: '64px', height: '64px' }} src={'/logo.png'} alt={'haccpForma'} />
                {/*<div className="font-bold">HACCP FORMAZIONE</div>*/}
              </div>
            </a>

            {/** navbar icon hamburgher */}
            <div className="w-1/2 pr-6 text-right content-center block sm:hidden">
              <button
                onClick={() => handleToggle()}
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white bg-primaryLabMar rounded-lg md:hidden hover:bg-white hover:text-primaryLabMar focus:outline-none"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>

            {/** navbar items desktop */}
            {showMenuMobile === false && (
              <div className="w-1/2 text-right content-center hidden sm:block sm:w-auto">
                <ul className="flex flex-row gap-4 items-center p-6 rounded-full justify-end text-primaryLabMar bg-white font-sans whitespace-nowrap">
                  {mainLinkDesktop.map((item, index) => {
                    const anchor = item.replace(' ', '').toLowerCase();

                    if (typeof prop.handleAnchorClick === 'function') {
                      return (
                        <li key={index} className={getState(index) ? 'hidden' : 'whitespace-nowrap'}>
                          {![3, 4, 5, 6, 7].includes(index) && (
                            <a
                              href={`#${anchor}`}
                              onClick={(event) => prop.handleAnchorClick(event, anchor)}
                              className={`${
                                [4].includes(index) &&
                                'bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar'
                              } `}
                            >
                              {item}
                            </a>
                          )}

                          {index === 3 && !omitLink.includes(location.pathname) && (
                            <a href={`/lavora-con-noi`}>{item}</a>
                          )}

                          {index === 4 && !omitLink.includes(location.pathname) && (
                            <a
                              href={`/registrati/?domain=${prop.currentDomain}`}
                              className={`bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                            >
                              {item}
                            </a>
                          )}

                          {index === 5 && !omitLink.includes(location.pathname) && (
                            <a
                              href={`/accedi?domain=${prop.currentDomain}`}
                              className={`bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                            >
                              {item}
                            </a>
                          )}

                          {index === 6 && !omitLink.includes(location.pathname) && (
                            <a
                              href={`/myprofile?domain=${prop.currentDomain}`}
                              className={`bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                            >
                              {item}
                            </a>
                          )}

                          {index === 7 && !omitLink.includes(location.pathname) && (
                            <button
                              onClick={() => {
                                logout();
                              }}
                              className={`bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                            >
                              {item}
                            </button>
                          )}
                        </li>
                      );
                    } else {
                      return (
                        <li key={index} className={getState(index) ? 'hidden' : 'whitespace-nowrap'}>
                          {![3, 4, 5, 6, 7].includes(index) && (
                            <a
                              href={`../#${anchor}`}
                              className={`${
                                [3].includes(index) &&
                                'bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar'
                              } `}
                            >
                              {item}
                            </a>
                          )}

                          {index === 3 && !omitLink.includes(location.pathname) && (
                            <a href={`/lavora-con-noi`}>{item}</a>
                          )}

                          {index === 4 && !omitLink.includes(location.pathname) && (
                            <a
                              href={`/registrati/?domain=${prop.currentDomain}`}
                              className={`bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                            >
                              {item}
                            </a>
                          )}

                          {index === 5 && !omitLink.includes(location.pathname) && (
                            <a
                              href={`/accedi?domain=${prop.currentDomain}`}
                              className={`bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                            >
                              {item}
                            </a>
                          )}

                          {index === 6 && !omitLink.includes(location.pathname) && (
                            <a
                              href={`/myprofile?domain=${prop.currentDomain}`}
                              className={`bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                            >
                              {item}
                            </a>
                          )}

                          {index === 7 && !omitLink.includes(location.pathname) && (
                            <button
                              onClick={() => {
                                logout();
                              }}
                              className={`bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                            >
                              {item}
                            </button>
                          )}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            )}

            {/** navbar items mobile */}
            {showMenuMobile === true && (
              <div className="w-full h-full fixed top-0 left-0 p-3 z-50 bg-primaryLabMar opacity-95">
                <div className="w-full h-full bg-black opacity-100 p-4 rounded-xl flex flex-col justify-center">
                  <ul className="flex flex-col gap-6 items-center justify-center text-white font-sans text-3xl font-semibold">
                    {mainLinkMobile.map((item, index) => {
                      const anchor = item.replace(' ', '').toLowerCase();

                      if (typeof prop.handleAnchorClick === 'function') {
                        return (
                          <li key={index} className={getState(index) ? 'hidden' : 'whitespace-nowrap'}>
                            {![3, 4, 5, 6, 7].includes(index) && (
                              <a
                                href={`#${anchor}`}
                                onClick={(event) => prop.handleAnchorClick(event, anchor)}
                                className={`${
                                  [4].includes(index) &&
                                  'bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar'
                                } `}
                              >
                                {item}
                              </a>
                            )}

                            {index === 3 && !omitLink.includes(location.pathname) && (
                              <a href={`/lavora-con-noi`}>{item}</a>
                            )}

                            {index === 4 && !omitLink.includes(location.pathname) && (
                              <a href={`/registrati/?domain=${prop.currentDomain}`}>
                                {item}
                              </a>
                            )}

                            {index === 5 && !omitLink.includes(location.pathname) && (
                              <a href={`/accedi?domain=${prop.currentDomain}`} >
                                {item}
                              </a>
                            )}
  
                            {index === 6 && !omitLink.includes(location.pathname) && (
                              <a href={`/myprofile?domain=${prop.currentDomain}`} >
                                {item}
                              </a>
                            )}
  
                            {index === 7 && !omitLink.includes(location.pathname) && (
                              <button
                                onClick={() => {
                                  logout();
                                }} >
                                {item}
                              </button>
                            )}
                          </li>
                        );
                      } else {
                        return (
                          <li key={index} className={getState(index) ? 'hidden' : 'whitespace-nowrap'}>
                            {![3, 4, 5, 6, 7].includes(index) && (
                              <a
                                href={`../#${anchor}`}
                                className={`${
                                  [3].includes(index) &&
                                  'bg-primaryLabMar font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar'
                                } `}
                              >
                                {item}
                              </a>
                            )}

                            {index === 3 && !omitLink.includes(location.pathname) && (
                              <a href={`/lavora-con-noi`}>{item}</a>
                            )}

                            {index === 4 && !omitLink.includes(location.pathname) && (
                              <a href={`/registrati/?domain=${prop.currentDomain}`}>
                                {item}
                              </a>
                            )}

                            {index === 5 && !omitLink.includes(location.pathname) && (
                              <a href={`/accedi?domain=${prop.currentDomain}`} >
                                {item}
                              </a>
                            )}
  
                            {index === 6 && !omitLink.includes(location.pathname) && (
                              <a href={`/myprofile?domain=${prop.currentDomain}`} >
                                {item}
                              </a>
                            )}
  
                            {index === 7 && !omitLink.includes(location.pathname) && (
                              <button
                                onClick={() => {
                                  logout();
                                }} >
                                {item}
                              </button>
                            )}
                          </li>
                        );
                      }
                    })}
                  </ul>

                  <div className="w-full mt-8">
                    <RiCloseCircleFill
                      size={44}
                      className="text-white mx-auto cursor-pointer"
                      onClick={() => {
                        handleToggle();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainMenuHeader;
