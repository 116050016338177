import React, { useEffect } from 'react';
import LoginForm from './LoginForm';
import Modal from './subComponent/Modal';
import PayPal from './subComponent/PayPal';
import Stripe from './subComponent/Stripe';
import BankTransfer from './subComponent/BankTransfer';
import CartDetail from './subComponent/CartDetail';
import ProfileData from './subComponent/ProfileData';
import moment from 'moment';
import { QRCodeCanvas } from 'qrcode.react';
import PdfCreate from './subComponent/PdfCreate';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { fetchImageData } from '../libs/utils';
import { isMobile } from 'react-device-detect';
import RenewalCourseV2 from './RenewalCourseV2';

const MyProfile = () => {
  const [showLogin, setShowLogin] = React.useState(true);
  const [profileData, setProfileData] = React.useState({});
  const [isShowModal, setIsShowModal] = React.useState(false);
  const [cartID, setCartID] = React.useState(0);
  const [CurrentGateway, setCurrentGateway] = React.useState(null);
  const [cartData, setCartData] = React.useState(null);
  const [activeCourse, setActiveCourse] = React.useState(false);

  const [forceRefresh, setForceRefresh] = React.useState(false);

  const paymentMethods = [
    {
      isActive: true,
      name: 'Paypal',
      description: 'effettua il pagamento sicuro',
      image: process.env.PUBLIC_URL + '/assets/paypal.png',
      gateway: 'paypal',
      className:
        'col-span-1 sm:col-span-1 lg:col-span-1 shadow-lg w-full text-center p-6 py-7 bg-white rounded-xl cursor-pointer',
      component: PayPal,
    },
    {
      isActive: false,
      name: 'Stripe',
      description: 'effettua il pagamento sicuro',
      image: process.env.PUBLIC_URL + '/assets/stripe.png',
      gateway: 'stripe',
      className:
        'col-span-1 sm:col-span-1 lg:col-span-1 shadow-lg w-full text-center p-6 py-7 bg-white rounded-xl cursor-pointer',
      component: Stripe,
    },
    {
      isActive: true,
      name: 'Bonifico bancario',
      description: 'effettua il pagamento in modo tradizionale',
      image: process.env.PUBLIC_URL + '/assets/bonificoBancario.png',
      gateway: 'banktranster',
      className:
        'col-span-1 sm:col-span-1 lg:col-span-1 shadow-lg w-full text-center p-6 py-7 bg-white rounded-xl cursor-pointer',
      component: BankTransfer,
    },
  ];

  const loadRemote = () => {
    const token = sessionStorage.getItem('token');

    try {
      fetch(`${process.env.REACT_APP_API_V2_URL}/myprofile/load?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          setProfileData(data.payload);
          setActiveCourse(data?.payload?.activeCourse || false);

          if (
            (data?.payload?.cartData || []).filter((item) => {
              return item.isActive === 0;
            }).length > 0
          ) {
            setCartData(
              (data?.payload?.cartData || []).filter((item) => {
                return item.isActive === 0;
              })[0]
            );
          }
        })
        .catch((error) => {
          sessionStorage.removeItem('token');
          setTimeout(() => {
            window.location.href = '/';
          }, 250);
          console.error('Errore nel recuperare dati profilo:', error);
        });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const loadRemoteCart = (cartID) => {
    const cartItem = (profileData?.cartData || []).find((item) => item.id === cartID);
    setCartData(cartItem);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      sessionStorage.setItem('token', token);
    }

    //---

    if (sessionStorage.getItem('token')) {
      setShowLogin(false);
      loadRemote();
    }
  }, []);

  useEffect(() => {
    //eslint-disable-next-line
  }, [profileData?.userData]);

  useEffect(() => {
    if (forceRefresh !== false) {
      loadRemote();
      loadRemoteCart(cartID);
    }
    //eslint-disable-next-line
  }, [forceRefresh]);

  //--- ---

  const go2pay = (cartID) => {
    setCurrentGateway(null);
    setCartID(cartID);
    setCartData(null);

    setTimeout(() => {
      loadRemoteCart(cartID);
    }, 250);
  };

  const deleteOrder = () => {
    try {
      fetch(`${process.env.REACT_APP_API_V2_URL}/cart/delete-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: sessionStorage.getItem('token'),
          cartID: cartID,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setCartID(0);
          setIsShowModal(false);
          loadRemote();
        })
        .catch((error) => {
          console.error('Errore nel cancellare ordine:', error);
        });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const go2Course = () => {
    const token = sessionStorage.getItem('token');
    sessionStorage.removeItem('token');
    window.location.href = `${process.env.REACT_APP_EXT_URL}/login/` + token;
  };

  const handleModal = (cartID) => {
    setIsShowModal(!isShowModal);
    setCartID(cartID);
  }; //handleModal

  const go2gateway = (cartID, gateway) => {
    const gatewayComponent = paymentMethods.find((item) => item.gateway === gateway)?.component;
    setCurrentGateway(() => gatewayComponent);

    if (!isMobile) {
      document.getElementById('paymentGatewayList').scrollIntoView({ behavior: 'smooth' });
    } else {
      document.getElementById('pinScrollForMobile').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const downloadCert = async (item, idParent = 0) => {
    if (idParent > 0) {
      return true;
    }

    try {
      const [result1, result2] = await Promise.all([
        fetchImageData('https://app.labmar.it/backend/v2/account/image-proxy/?type=app'),
        fetchImageData(
          'https://app.labmar.it/backend/v2/account/image-proxy?type=tutor&token=' + sessionStorage.getItem('token')
        ),
      ]);

      if (result1 && result2) {
        item.logoApp = result1.logo;
        item.mimeApp = result1.mime;
        item.logoTutor = result2.logo;
        item.mimeTutor = result2.mime;

        const base64Image = document.getElementById(item.code) ? document.getElementById(item.code).toDataURL() : null;
        if (base64Image) {
          const blob = await pdf(<PdfCreate qrcode={base64Image} data={item} />).toBlob();
          saveAs(blob, `certificato-haccp-${item?.name}-${item?.surname}-${item?.progress}-${item?.aa}`);
        }
      } else {
        console.error('Una delle chiamate fetch non ha restituito un risultato valido');
      }
    } catch (error) {
      console.error('Errore durante il download del certificato', error);
    }
  };

  //window.open(process.env.REACT_APP_EXT_URL+'/verifyQrCode?verify=' + item?.code, '_blank');

  const lastCertProtocollo = profileData?.certificateData
    ? (profileData?.certificateData[0] || [])?.aa + '/' + profileData?.certificateData[0]?.progress
    : '';
  const lastCertExpiredAt = profileData?.certificateData ? (profileData?.certificateData[0] || [])?.expiredAt : '';
  const dayDiff = moment(lastCertExpiredAt).diff(moment(), 'days');

  //--- ---

  return (
    <>
      {isShowModal && <Modal setIsShowModal={setIsShowModal} handleAction={deleteOrder} />}

      {showLogin && (
        <div>
          <div className="text-xl font-semibold mb-8">
            Accedi alla tua area riservata verifica i dati del tuo profilo. Potrai seguire le lezioni attive comodamente
            da remoto. Per accedere devi utilizzare email e password che hai inserito in fase di registrazione.
          </div>
          <LoginForm />
        </div>
      )}

      {!showLogin && (
        <div>
          {profileData?.userData && <ProfileData profileData={profileData} />}

          <>
            {parseInt(profileData?.userData?.idParent || 0) === 0 && parseInt(dayDiff) <= 365 && (
              <section>
                <div
                  id="alert-additional-content-1"
                  className="p-4 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
                  role="alert"
                >
                  <div className="flex items-center">
                    <svg
                      className="shrink-0 w-4 h-4 me-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <h3 className="text-lg font-bold">Messaggio di Servizio</h3>
                  </div>
                  <div className="mt-2 mb-4 text-lg">
                    Il tuo Attestato numero di protocollo <b>{lastCertProtocollo}</b> è in scadenza il{' '}
                    <b>{moment(lastCertExpiredAt).format('DD/MM/YYYY')}</b>. Ricordati di rinnovare il tuo attestato per
                    non interrompere la tua attività lavorativa.
                  </div>
                  {/*<div className="flex">
                    <button type="button" className="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                        <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                      </svg>
                      View more
                    </button>
                    <button type="button" className="text-blue-800 bg-transparent border border-blue-800 hover:bg-blue-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-blue-600 dark:border-blue-600 dark:text-blue-400 dark:hover:text-white dark:focus:ring-blue-800" data-dismiss-target="#alert-additional-content-1" aria-label="Close">
                      Dismiss
                    </button>
                  </div>*/}

                  {(profileData?.cartData || []).filter((item) => {
                    return item.isActive === 0 && item.deletedAt === null;
                  }).length === 0 && <RenewalCourseV2 currentDomain={profileData?.userData?.domain} />}
                </div>
              </section>
            )}

            {!activeCourse && cartData !== null && (
              <section
                id="paymentGatewayList"
                className="bg-white border-2 border-green-400 rounded-xl px-4 py-4 w-full mb-6 mt-6"
              >
                {profileData?.cartData?.length > 0 && (
                  <CartDetail
                    cartData={cartData}
                    paymentMethods={paymentMethods}
                    go2gateway={go2gateway}
                    setForceRefresh={setForceRefresh}
                    setCurrentGateway={setCurrentGateway}
                  />
                )}
                {CurrentGateway && <CurrentGateway cartData={cartData} userData={profileData?.userData} />}
              </section>
            )}

            <section>
              <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mx-auto w-full my-10 mt-4 mb-0">
                {parseInt(profileData?.userData?.idParent || 0) === 0 && (
                  <li className="w-full px-5 py-3 border border-gray-300 rounded-xl">
                    <h3 className="text-lg font-semibold mb-3 pb-1 border-b border-gray-400">
                      Corsi in attesa di pagamento
                    </h3>
                    <ul>
                      {(profileData?.cartData || [])
                        .filter((item) => {
                          return item.isActive === 0 && item.deletedAt === null;
                        })
                        .map((item, index) => (
                          <li
                            key={index}
                            className="text-sm font-medium text-gray-900 mb-2 pb-2 pl-2 border-l-2 border-orange-300"
                          >
                            {item.description}
                            <div className="font-bold text-md mt-1">
                              <span className={`${item.usedBonus !== null && item.usedBonus !== '' && 'line-through'}`}>
                                da pagare: € {(parseFloat(item.price) > 0 ? parseFloat(item.price) : 0).toFixed(2)}
                              </span>
                            </div>
                            {item.usedBonus !== null && item.usedBonus !== '' && (
                              <div className="text-md font-bold mt-0 text-red-900">
                                da pagare con Promo: €{' '}
                                {(parseFloat(item.priceDiscount) > 0 ? parseFloat(item.priceDiscount) : 0).toFixed(2)}
                              </div>
                            )}
                            <div className="font-bold text-md mt-1 hidden">registrazione: {item.registrationCode}</div>
                            <div className="flex flex-row gap-1 my-3">
                              {!activeCourse ? (
                                <button
                                  className="w-full bg-green-800 text-white p-4 m-0 rounded-lg pt-1 pb-2"
                                  onClick={() => {
                                    go2pay(item.id);
                                  }}
                                >
                                  paga ed avvia corso
                                </button>
                              ) : (
                                <button className="w-full bg-gray-200 text-gray-800 p-4 m-0 rounded-lg pt-1 pb-2">
                                  Hai già un corso attivo.
                                  <br />
                                  Per accedere al nuovo corso devi prima completare quello attivo.
                                </button>
                              )}
                              {(profileData?.cartData || []).filter((item) => {
                                return item.isActive === 0 && item.deletedAt === null;
                              }).length > 1 && (
                                <button
                                  className="w-1/2 bg-red-800 text-white p-4 m-0 rounded-lg pt-1 pb-2"
                                  onClick={() => {
                                    handleModal(item.id);
                                  }}
                                >
                                  cancella ordine
                                </button>
                              )}
                            </div>
                          </li>
                        ))}

                      {(profileData?.cartData || []).filter((item) => {
                        return item.isActive === 0 && item.deletedAt === null;
                      }).length === 0 && (
                        <li className="text-sm font-medium text-gray-900 mb-2 pb-2 pl-2 border-l-2 border-orange-300">
                          Nessun corso in attesa di pagamento
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                <li className="w-full px-5 py-3 border border-gray-300 rounded-xl">
                  <h3 className="text-lg font-semibold mb-3 pb-1 border-b border-gray-400">Corsi Attivi</h3>
                  <ul>
                    {(profileData?.cartData || [])
                      .filter((item) => {
                        return item.isActive === 1 && item.deletedAt === null && item.statusUC === 'inProgress';
                      })
                      .map((item, index) => (
                        <li
                          key={index}
                          className="text-sm font-medium text-gray-900 mb-2 pb-2 pl-2 border-l-2 border-blue-300"
                        >
                          {item.description}

                          <div className="flex flex-row gap-1 my-3">
                            <button
                              className="w-full bg-blue-800 text-white p-0 m-0 rounded-lg pt-1 pb-2"
                              onClick={() => {
                                go2Course();
                              }}
                            >
                              accedi al corso
                            </button>
                          </div>
                        </li>
                      ))}

                    {(profileData?.cartData || []).filter((item) => {
                      return item.isActive === 1 && item.deletedAt === null && item.statusUC === 'inProgress';
                    }).length === 0 && (
                      <li className="text-sm font-medium text-gray-900 mb-2 pb-2 pl-2 border-l-2 border-blue-300">
                        Nessun corso attivo
                      </li>
                    )}
                  </ul>
                </li>
                <li className="w-full px-5 py-3 border border-gray-300 rounded-xl">
                  <h3 className="text-lg font-semibold mb-3 pb-1 border-b border-gray-400">Certificati</h3>
                  <ul>
                    {(profileData?.certificateData || [])
                      .filter((item) => {
                        return item.releasedAt || item.pending === 1;
                      })
                      .map((item, index) => (
                        <li
                          key={index}
                          className="text-sm font-medium text-gray-900 mb-2 pb-2 pl-2 border-l-2 border-green-300"
                        >
                          <ul
                            className={`flex flex-row gap-3 justify-center ${
                              parseInt(profileData?.userData?.idParent || 0) === 0 ? 'cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              downloadCert(item, parseInt(profileData?.userData?.idParent || 0));
                            }}
                          >
                            <li className="w-80">
                              {item.pending === 1 ? (
                                <div className="font-semibold text-red-900 text-lg">
                                  certificato in fase di emissione
                                </div>
                              ) : (
                                ''
                              )}
                              <div className="font-semibold">{item.courseTitle}</div>
                              <div>
                                <b>rilasciato il:</b>{' '}
                                {item.pending === 1 ? '--- ---' : moment(item.releasedAt).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <b>scade il:</b>{' '}
                                {item.pending === 1 ? '--- ---' : moment(item.expiredAt).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <b>certificato n°:</b> {item.pending === 1 ? '--' : item.progress} <b>del</b> {item.aa}
                              </div>
                            </li>
                            <li className="w-20">
                              {item.pending === 1 ? (
                                ''
                              ) : parseInt(profileData?.userData?.idParent || 0) === 0 ? (
                                <div>
                                  <QRCodeCanvas
                                    size={80}
                                    value={'https://app.labmar.it/verifyQrCode?verify=' + item?.code}
                                    id={item?.code}
                                  />
                                  <p className="text-center leading-4 mt-1 text-red-900">scarica attestasto</p>
                                </div>
                              ) : (
                                ''
                              )}
                            </li>
                          </ul>
                        </li>
                      ))}

                    {(profileData?.certificateData || []).filter((item) => {
                      return item.releasedAt || item.pending === 1;
                    }).length === 0 && (
                      <li className="text-sm font-medium text-gray-900 mb-2 pb-2 pl-2 border-l-2 border-green-300">
                        Nessun certificato rilasciato
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </section>
          </>
        </div>
      )}
    </>
  );
};

export default MyProfile;
